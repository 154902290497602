import React, { useState } from "react";
import CRUD from '../../components/com/crud/CRUD';
import { getService } from "../../services/";
import { URL_BASE, URL_IMPORT_CAREGORIES } from "../../constants/";
import { downloadLink } from "../../utils/Helper";

import {
    TextInput,
    Filter,
    BooleanInput
} from "react-admin";
import ProductShow from "./ProductShow";
import ShowProductCategory from "./ShowProductCategory";
import { Button, message, Upload } from "antd";
const columns = [
    {
        xtype: "textfield",
        source: "id_codigo",
        label: "Id Codigo"
    },
    {
        xtype: "textfield",
        source: 'codigo',
        label: "Codigo"
    },
    {
        xtype: "textfield",
        source: 'descripcion',
        label: "Descripción",

    },
    /* {
        xtype: "textfield",
        source: 'desc_Grupo',
        label: "Grupo"
    }, */
    {
        xtype: "textfield",
        source: 'porcentaje_iva',
        label: "% IVA"
    },
    {
        xtype: "textfield",
        source: 'Stock',
        label: "Stock"
    },
    {
        xtype: "textfield",
        source: 'Pedido',
        label: "Pedido"
    },
    {
        xtype: "customfield",
        render: (record) => {
            return record.categories.length > 0 ? `(${record.categories.length})` :
                <span style={{
                    color: "red"
                }}>Sin Categorias</span>;
        },
        source: 'categories',
        label: "Categorias"
    },

];
const config = {
    action: URL_IMPORT_CAREGORIES,
    name: "product_file",
    accept: ".xls,.xlsx",
    headers: {
        Authorization: localStorage.getItem("feathers-jwt")
    },
    onChange({ file, fileList }) {
        if (file.status !== 'uploading') {
            console.log(file, fileList);
        }
    }
};
const Filters = props => {

    const [exporting, setExporting] = useState(false);
    const ExportExcel = () => {
        setExporting(true);
        const service = getService("export-categories");
        service.find({})
            .then(url => {
                console.log(url);
                setExporting(false);
                downloadLink(`${URL_BASE}${url}`, "report.xls");
            }).catch(err => {
                message.error(err.message)
            });
    }
    return (
        <Filter {...props}>
            <TextInput label="ID" source="id_codigo" alwaysOn />
            <TextInput label="Codigo" source="codigo" alwaysOn />
            {/*  <BooleanInput
            style={{
                margin: 18
            }}
            label="¿Tiene Categorias?"
            source="has_categories"
            alwaysOn /> */}
            <Button
                style={{
                    margin: "18px 4px"
                }}
                alwaysOn
                size="large"
                type="primary"
                block>
                <a
                    href={`https://backendweb.avantika.com.co/algolia`}
                    target="_blank"
                >SINCRONIZAR ALGOLIA</a>
            </Button>
            <Button
                style={{
                    margin: "18px 4px"
                }}
                loading={exporting}
                icon="file-excel"
                alwaysOn
                onClick={ExportExcel}
                size="large"
                type="dashed"
                block>
                EXPORTAR A EXCEL
                </Button>
            <Upload alwaysOn {...config}>
                <Button
                    style={{
                        margin: "18px 4px"
                    }}
                    icon="file-excel"
                    size="large"
                    type="dashed"
                    block>
                    IMPORTAR EXCEL
                </Button>
            </Upload>
        </Filter>
    )
};
const View = props => {
    return <CRUD
        filters={<Filters />}
        show={<ShowProductCategory title={"Agregar Categorias"} />}
        actions={{
            create: false,
            delete: false,
            show: true,
            edit: false,
        }}
        columns={columns}
    /*  fields={fields} */
    />
}
export default View;