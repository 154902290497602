export const URL_BASE =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://backendweb.avantika.com.co"
    : "https://backendweb.avantika.com.co";
export const URL_AUTHENTICATION = "/authentication";

export const DEFAULT_AVATAR_IMAGE = "/images/avatar.png";
export const DEFAULT_IMAGE = "/images/mockup.png";

export const URL_S3_SERVER =
  window.location.hostname === "localhost"
    ? /* ? "http://localhost:3030" */ "https://backendweb.avantika.com.co"
    : "https://backendweb.avantika.com.co";
export const URL_S3 = "https://appartaapp.s3.amazonaws.com/";


export const URL_IMPORT_CAREGORIES = `${URL_BASE}/import-categories`;