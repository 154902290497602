import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Factory from "./Factory";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import {
    Create,
    SimpleForm,
    useEditController
} from "react-admin";

const FormContainer = styled.div`
    min-width:400px;
    min-height:100vh;

    padding:0px;
    margin:0px;

    & .MuiToolbar-root{
        /* position: absolute!important; */
        /* bottom: 0!important; */
        width: 100%!important;
    }
    & .head-title{
        display: flex;
        color: var(--color-white)!important;
        justify-content: space-between;
        padding: 0px 16px;
        margin-top: 10px!important;
        margin-bottom: 0px!important;
    }
    & .MuiPaper-root{
        box-shadow:none!important;
    }
`
const CreateView = ({ onCancel, editform, primaryKey = "id", ...props }) => {
    const history = useHistory();
    const [fields, setFields] = useState([]);
    const getFields = () => {
        let { fields, columns } = props;
        fields = fields || columns;
        fields = fields
            .filter(it => (it.source != "_id" && it.source != primaryKey))
            .map(field => (
                <Factory
                    input
                    style={{
                        width: `${
                            field.fullWidth ? 100 :
                                field.flex ? Number(field.flex) * 100 : 50}%`
                    }} input {...field} />
            ))
        setFields(fields);
    }
    const handleClose = useCallback(() => {
        if (!props.redirect) {
            history.push(`/${props.basePath}`);
        } else {
            history.replace(props.redirect);
        }
    }, [history]);

    const handleOnCancel = () => {
        /*  handleClose(); */
        if (onCancel) return onCancel();
    };
    useEffect(() => {
        getFields();
    }, [])
    return <FormContainer>
        <div className="head-title">
            {<Typography variant="h6">{`${
                props.mode == "sidebar" && !props.title ? "Crear" :
                    props.title
                }`}</Typography>}
            {props.mode == "sidebar" && <IconButton onClick={handleOnCancel}>
                <CloseIcon />
            </IconButton>}
        </div>
        {<Create
            /*  className={classes.form} */
            {...props}
            /* basePath={`/${props.basePath}`} */
            redirect={props.redirect ? props.redirect : "list"}
            submitOnEnter={props.submitOnEnter || false}
            resource={props.resource}
            record={props.record}
        >
            <SimpleForm
                submitOnEnter={props.submitOnEnter || false}
            >
                <Grid fullWidth spacing={16}>
                    {fields}
                </Grid>
            </SimpleForm>
        </Create>}
    </FormContainer>
}
export default CreateView;