import React, { Component } from "react";
// import { notification , message, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import S3Uploader from "react-s3-uploader";
import { Progress } from "antd";
import { URL_S3 } from "../../constants/";
import styled from "styled-components";
const WrapperImage = styled.div`
  margin: 0px auto;
  padding: 4px;
  max-width: 200px;
  position: relative;
  display: block;
  & img{
    object-fit: contain;
    max-width: 100%;
    object-position: center;
  }
`
const FileWrapper = styled.div`
  & .flat-button-file{
    max-width:100%!important;
  }
`
class FileField extends Component {
  state = {
    progress: null,
    file: {},
    image: null,
  };

  componentDidMount() { }
  componentWillReceiveProps(nextprops) {
    if (nextprops.image)
      this.setState({
        image: nextprops.image
      });
    if (nextprops.record && nextprops.source)
      this.setState({
        image: nextprops.record[nextprops.source][nextprops.name || "path"]
      });
  }
  onUploadStart = (file, next) => {
    this.setState({
      name_file: file.name,
      file
    });
    next(file);
  };

  onSignedUrl = (...props) => { };

  onUploadProgress = (progress, ...props) => {
    this.setState({ progress });
  };

  onUploadError = (error) => { };

  onUploadFinish = (files) => {
    let { file } = this.state;
    const { match: { params } } = this.props;
    this.setState({
      image: files.fileKey
    });
    if (this.props.onFinish)
      this.props.onFinish(files.fileKey, file, params.id || this.props.id);
  };

  render() {
    const { file = {}, match, path, finalPath, data = {}, idKey = "id" } = this.props;
    const { progress, image } = this.state;
    let pathNew = null;
    if (match) {
      const id = match.params.id || data[idKey] || this.props.id;
      if (this.props.finalPath) {
        pathNew = `${path}/${id}/${finalPath}`;
      } else {
        pathNew = `${path}/${id}`;
      }
    }

    if (file.name) return <div className="s3Button mr-2">{file.name}</div>;
    return (
      <FileWrapper className="mr-2">
        {image ? (
          <WrapperImage>
            <img
              src={`${URL_S3}${image}`}
              className="custom-img-field"
            />
          </WrapperImage>
        ) : null}
        {(progress > 0 && progress < 100) && <Progress percent={progress} status="active" />}
        <label
          htmlFor={this.props.idComponent}
          className="flat-button-file"
          variant="outlined"
          color="primary"
        >
          {this.props.label ? this.props.label : "Subir Imágen "}
        </label>
        <label className="s3Button">
          <S3Uploader
            id={this.props.idComponent}
            signingUrl="/s3Client/sign"
            signingUrlMethod="GET"
            accept="*/*"
            s3path={pathNew}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            server="https://api.apparta.co"
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: "hidden" }}
          />
        </label>
      </FileWrapper>
    );
  }
}

export default withRouter(FileField);
