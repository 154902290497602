import React from "react";
import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField } from "react-admin";
const ListView = props => (
  <List
    {...props}
    exporter={false}
    filter={{ parent_id: 12 }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField labal="Categoria Padre" source="parent_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton label="Mostrar hijos" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ListView;
