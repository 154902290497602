import React from "react";
import {
    SelectInput
} from "react-admin";
const status = [
    { id: "active", name: "Activo" },
    { id: "inactive", name: "Inactivo" }
];

const StatusSelectInput = props => {

    return <SelectInput
        choices={props.choices || status}
        label="Estado"
        source="status"
        optionText="name"
        optionValue="id"
        alwaysOn
        {...props}

    />
}

export default StatusSelectInput;