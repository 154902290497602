import React, { useEffect, useState } from "react";
import { Table, message, Row, Col, Button, Input, Modal, Select } from "antd";

import { getService } from "../../services/";
import { Link } from "react-router-dom";
const { confirm } = Modal;
const { Option } = Select;
const service = getService("products-categories");
const CategoryService = getService("categories");

const columns = [
    {
        key: "id",
        dataIndex: "category_id",
    },
    {
        key: "name",
        dataIndex: "category_name",
    },
];
const Title = () => (<h2>Categorias</h2>);
const ShowProductCategory = ({ record }) => {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [value, setValue] = useState();
    const handleSave = () => {
        setSubmiting(true)
        service.create({
            category_id: value,
            product_id: record.codigo
        })
            .then(response => {
                getData();
                setValue("");
                setSubmiting(false)
            })
            .catch(err => {
                setValue("");
                setSubmiting(false)
                message.error(err.message)
            });
    }
    const handleSent = () => {

        //https://backendweb.avantika.com.co/algolia?$client[products][0]=21.373.17

        setSubmiting(true)
        service.create({
            category_id: value,
            product_id: record.codigo
        })
            .then(response => {
                getData();
                setValue("");
                setSubmiting(false)
            })
            .catch(err => {
                setValue("");
                setSubmiting(false)
                message.error(err.message)
            });
    }
    const handleDelete = (id) => {
        service.remove(id)
            .then(response => {
                getData();
                setValue("");
            })
            .catch(err => {
                setValue("");
                setLoading(false);
                message.error(err.message)
            });
    }
    const getData = (query) => {
        setLoading(true);
        service.find({
            query: {
                product_id: record.codigo
            }
        })
            .then(({ data }) => {
                setLoading(false);
                setData(data)
            }).catch(err => {
                setLoading(false);
                message.error(err.message)
            });
    }
    const getCategories = (query) => {
        setLoading(true);
        CategoryService.find({
            query: {
                $limit: 50000
            }
        })
            .then(({ data }) => {
                setLoading(false);
                setCategories(data)
            }).catch(err => {
                setLoading(false);
                message.error(err.message)
            });
    }

    useEffect(() => {
        getData();
        getCategories();
    }, [])
    return <>
        <h2>Categorias</h2>
        <Row gutter={8} type="flex" justify="start" align="middle">
            <Col span={70} style={{
                position: "relative"
            }}>

                <Select
                    showSearch
                    style={{
                        position: "relative",
                        width: 300
                    }}
                    placeholder="Seleccione Categoria"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={value => setValue(value)}
                >
                    {categories.map(it => (<Option value={it.id}>{it.name}</Option>))}

                </Select>
            </Col>
            <Col span={30}>
                <Button
                    loading={submiting}
                    onClick={handleSave}
                    size="large"
                    type="primary">AGREGAR</Button>
            </Col>
        </Row>
        <Table
            loading={loading}
            showHeader={false}
            pagination={false}
            columns={[...columns, {
                key: 'tags',
                dataIndex: 'id',
                width: 60,
                render: id => (
                    <>
                        <Button
                            type="link"
                            shape="circle"
                            icon="delete"
                            onClick={() => handleDelete(id)}
                        />
                    </>
                )
            }]}
            dataSource={data} />
        <Row gutter={8} type="flex" justify="start" align="middle">
            <Col span={24}>
                <Button
                    size="large"
                    type="primary"
                    block>
                    <a
                        href={`https://backendweb.avantika.com.co/algolia?$client[products][0]=${record.codigo}`}
                        target="_blank"
                    >ENVIAR A ALGOLIA</a>
                </Button>
            </Col>
        </Row>
    </>
}

export default ShowProductCategory;